import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseData } from '../response';
import 'rxjs/add/operator/map';

@Injectable({
    providedIn: 'root'
})
export class UserdetailService {

    constructor(
        private http: HttpClient
        ) {
    }

    getData(url, options = {}): Promise<ResponseData[]> {
        return this.http.get(url, options).toPromise().then((data : any) => this.extractData(data)).catch(err => {
            return Promise.reject(err.error || 'Server error');
        });
    }

    extractData(data) {
        return Promise.resolve(data);
    }
}