import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, HashLocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RbAuthInterceptor } from './shared/auth/rbauth.interceptor';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/component/header-navigation/navigation.component';
import { BreadcrumbComponent } from './shared/component/breadcrumb/breadcrumb.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/component/spinner.component';
import { AuthGuard, RoleGuard } from './shared/auth/auth.guard';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { sharedService } from './shared/service/shared-service.service';
import { NgxLoadingModule } from 'ngx-loading';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 2,
  wheelPropagation: true,
};

@NgModule({
  declarations: [
  AppComponent,
  SpinnerComponent,
  FullComponent,
  BlankComponent,
  NavigationComponent,
  BreadcrumbComponent
  ],
  imports: [
  CommonModule,
  BrowserModule,
  HttpModule,
  BrowserAnimationsModule,   
  FormsModule,
  HttpClientModule,
  NgbModule.forRoot(),  
  PerfectScrollbarModule,
  AppRoutingModule,
  ReactiveFormsModule,
  ToastrModule.forRoot({
    preventDuplicates: true,
  }),
  NgSelectModule,
  NgxLoadingModule.forRoot({})
  ],
  providers: [
  { provide: HTTP_INTERCEPTORS, useClass: RbAuthInterceptor, multi: true },
  {
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  },
  AuthGuard,
  RoleGuard,
  ToastrService,
  sharedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }