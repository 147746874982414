import * as $ from 'jquery';
import { Component, AfterViewInit, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from '../../appSettings';
import { UserdetailService } from '../../service/userdetail.service';
import { sharedService } from '../../service/shared-service.service';
import { Subscription } from 'rxjs/Subscription';

@Component({
    selector: 'ap-navigation',
    templateUrl: './navigation.component.html'
})
export class NavigationComponent implements AfterViewInit {
	name:string;

    constructor(
        public router: Router,
        private userdetailservice: UserdetailService,
        private sharedService: sharedService
        ) {
        this.profile_image_update = this.sharedService.profile_image_update.subscribe(value => {
            this.userData = this.sharedService.getItem('user_details', 'object');
            this.getUserData();
        });
    }

    url;
    userData:any;
    profile_image_update: Subscription;
    currentUser;
    errMsg;
    file_url = '';

    ngAfterViewInit() {
        var set = function() {
            var width = (window.innerWidth > 0) ? window.innerWidth : this.screen.width;
            var topOffset = 0;
            if (width < 1170) {
                $("#main-wrapper").addClass("mini-sidebar");
            } else {
                $("#main-wrapper").removeClass("mini-sidebar");
            }
        };
        $(window).ready(set);
        $(window).on("resize", set);

        $(".search-box a, .search-box .app-search .srh-btn").on('click', function () {
            $(".app-search").toggle(200);
        });

        $("body").trigger("resize");
    }

    logoutUser() {
        this.url = AppSettings.LOGOUT;
        this.userdetailservice.getData(this.url)
        .then(data => {
            if (data['code'] == 200) {
                const isIFrameUser = this.sharedService.getItem('isIFrameUser');
                const iframeToken = this.sharedService.getItem('iframetoken');
                this.sharedService.clearLocalStorage();
                if (isIFrameUser) {
                    this.sharedService.routerNavigate('/fv-login/'+iframeToken);
                } else {
                    this.sharedService.routerNavigate('/login');
                }
            }
        })
    }

    ngOnInit() {
        this.userData = this.sharedService.getItem('user_details', 'object');
    }

    getUserData() {
        this.url = AppSettings.PROFILE;
        this.userdetailservice.getData(this.url)
        .then(data => {
            if (data['code'] == 200) {              
                this.currentUser = data['data'];
                this.file_url =  (data['data'].image_url ? data['data'].image_url : 'assets/images/default.png');
            } 
        }, err=> {
            if ( err['code'] == 400 ) {
                this.errMsg = err['msg'];
            }
            if ( err['code'] == 401 ) {
                this.sharedService.routerNavigate('/login');
            }
        });
    }

    isSuperAdmin() {
        return this.sharedService.isSuperAdmin();
    }

    isOrgAdmin() {
        return this.sharedService.isOrgAdmin();
    }

    isIFrameUser() {
        return this.sharedService.isIFrameUser();
    }
}