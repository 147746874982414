import { Injectable } from '@angular/core'
import { ResponseData } from '../response';
import { ToastrService } from 'ngx-toastr';
import swal from 'sweetalert2';
import { Location } from '@angular/common';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/map';
import { Router} from '@angular/router';

@Injectable()
export class sharedService {

	constructor(
        private toastr: ToastrService,
        private location: Location,
        private router: Router
        ) {
    }

    profile_image_update = new Subject<any>();
    ADMIN_ROLE = 1;
    OFFICE_MANAGER_ROLE = 2; // Org Admin
    USER_ROLE = 3;
    RESELLER_ROLE = 4; // Org Admin
    IFRAME_ROLE = 5;
    RINGCENTRAL_ADMIN_ROLE = 7; // Org Admin
    defaultProjectChanged = new Subject<any>();
    organization_filter = new Subject<any>();
    NOT_FOUND_MSG = 'No data found!';
    INTERNAL_SERVER_MSG = 'Internal server error!';

    //User role 2, 4, 7 has same access - they all are org admin

    validateEmail(email) {
        var mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return email.match(mailformat);
    }

    errorMsgsToaster(err) {
        if (typeof err === 'string') {
            this.toastr.error(err);
        } else if (err['message'] && typeof err['message'] == 'object' && err['message'][0]) {
            this.toastr.error(err['message'][0]);
        } else if (err['message'] && typeof err['message'] == 'string') {
            this.toastr.error(err['message']);
        } else {
            this.toastr.error('Something went wrong, Please try again...!');
        }
    }

    errorMsgs(err) {
        if (err['code'] == 500) {
            this.toastr.error(this.INTERNAL_SERVER_MSG);
        } else if (err['code'] == 404) {
            this.toastr.info(this.NOT_FOUND_MSG);
        } else {
            this.toastr.error('Something went wrong, Please try again...!');
        }
    }

    successMsgsToaster(msg) {
        this.toastr.success(msg);
    }

    swalPopup(title, text, type, confirmButtonText, showCancelButton = true, allowOutsideClick = false, callback) {
        swal({
            title: title,
            text: text,
            type: type,
            confirmButtonText: confirmButtonText,
            showCancelButton: showCancelButton,
            allowOutsideClick: allowOutsideClick
        }).then((result) => {
            callback(result);
        });
    }

    jsonStringify(data) {
        return JSON.stringify(data);
    }

    jsonParse(data) {
        return JSON.parse(data);
    }

    setItem(keyname, data) {
        if (typeof data == 'object') {
            this.setDataToLocalStorage(keyname, this.jsonStringify(data));
        } else {
            this.setDataToLocalStorage(keyname, data);
        }
    }

    getItem(keyname, needAs = 'string') {
        if (needAs === 'object') {
            return this.jsonParse(this.getDataFromLocalStorage(keyname));
        }
        return this.getDataFromLocalStorage(keyname);
    }

    setDataToLocalStorage(keyname, data) {
        localStorage.setItem(keyname, data);
    }

    getDataFromLocalStorage(keyname) {
        return localStorage.getItem(keyname);
    }

    removeItem(keyname) {
        localStorage.removeItem(keyname);
    }

    clearLocalStorage() {
        localStorage.clear();
    }

    checkEmpty(value) {
        return ( value == '' || value == undefined || value == false )
    }

    goBack() {
        this.location.back();
    }

    check_if_json(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    isSuperAdmin() {
        return this.isUserRole(this.ADMIN_ROLE);
    }

    isOfficeManagerUser() {
        return this.isUserRole(this.OFFICE_MANAGER_ROLE);
    }

    isUser() {
        return this.isUserRole(this.USER_ROLE);
    }

    isUserRole(role) {
        return (this.getCurrentUserRole() == role);
    }

    getCurrentUserRole() {
        const userDetails = this.getItem('user_details', 'object');
        if (userDetails) 
            return userDetails.role;
        return false;
    }

    navigateUserByRole(token=null) {
        if (this.isSuperAdmin()) {
            this.routerNavigate('/organization');
        } else if (this.isOrgAdmin()) {
            this.routerNavigate('/user');
        } else if (this.isUser()) {
            this.routerNavigate('/call-logs');
        } else if (this.isIFrameUser()) {
            this.routerNavigate('/fv-note/'+token);
        }
    }

    routerNavigate(path) {
        if (path) {
            this.router.navigate([path]);
        }
    }

    phoneMasking(event) {
        if (event) {
            let newVal = event.replace(/\D/g, '');
            if (newVal.length === 0) {
                newVal = '';
            } else if (newVal.length <= 3) {
                newVal = newVal.replace(/^(\d{0,3})/, '($1)');
            } else if (newVal.length <= 6) {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1)$2');
            } else if (newVal.length <= 9) {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '($1)$2-$3');
            } else if (newVal.length <= 10) {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})/, '($1)$2-$3');
            } else if (newVal.length <= 11) {
                newVal = newVal.replace(/^(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,3})/, '+$1 ($2)$3-$4');
            } else if (newVal.length <= 12) {
                newVal = newVal.replace(/^(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,3})/, '+$1 ($2)$3-$4');
            } else if (newVal.length <= 13) {
                newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})/, '+$1 ($2)$3-$4');
            } else {
                newVal = newVal.substring(0, 13);
                newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,3})/, '+$1 ($2)$3-$4');
            } 
            return newVal;
        }
    }

    isIFrameUser() {
        return this.isUserRole(this.IFRAME_ROLE);
    }

    copyToClipboard(value= null) {
        var copyElement = document.createElement("textarea");
        copyElement.style.position = 'fixed';
        copyElement.style.opacity = '0';
        copyElement.textContent = decodeURI(value);
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(copyElement);
        copyElement.select();
        document.execCommand('copy');
        body.removeChild(copyElement);
        return true;
    }

    isResellerUser() {
        return this.isUserRole(this.RESELLER_ROLE);
    }

    isRingCentralAdminUser() {
        return this.isUserRole(this.RINGCENTRAL_ADMIN_ROLE);
    }

    isOrgAdmin() {
        if (this.isOfficeManagerUser() || this.isResellerUser() || this.isRingCentralAdminUser()) {
            return true;
        }
        return false;
    }

    onCancel() {
        this.location.back();
    }

}