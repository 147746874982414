import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { sharedService } from '../../shared/service/shared-service.service';

@Component({
    selector: 'full-layout',
    templateUrl: './full.component.html',
    styleUrls: ['./full.component.scss']
})
export class FullComponent implements OnInit {

    public config: PerfectScrollbarConfigInterface = {};
    constructor(
        public router: Router,
        private sharedService: sharedService
        ) {
    }

    ngOnInit() {
        if (this.router.url === '/' || !this.router.url) {
            this.sharedService.navigateUserByRole();
        }
    }
}