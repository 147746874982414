import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { sharedService } from '../service/shared-service.service';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
		private sharedService: sharedService
		) {
	}
	
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (!this.checkEmpty(localStorage.getItem('user_credentials'))) {
			return true;
		} else {
			this.sharedService.routerNavigate('/login');
			return false;
		}
	}

	checkEmpty(value) {
		return ( value == '' || value == undefined || value == false )
	}
}

@Injectable()
export class RoleGuard implements CanActivate {

	constructor(
		private sharedService: sharedService,
		) {
	}
	
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		const userRole = this.sharedService.getCurrentUserRole();
		if ((next.data.role).includes(userRole)) {
			return true;
		}
		this.sharedService.navigateUserByRole();
		return false;
	}
}