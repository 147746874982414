import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from "rxjs";
import { fromPromise } from 'rxjs/observable/fromPromise';
import 'rxjs/add/operator/switchMap';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { sharedService } from '../service/shared-service.service';

@Injectable()
export class RbAuthInterceptor implements HttpInterceptor {
	constructor(
		private router: Router,
		private sharedService: sharedService
		) {
	}
	
	intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		let headers1 = {
			'Content-Type':  'application/json',
		};
		let user_credentials = this.sharedService.getItem('user_credentials');
		if (!this.sharedService.checkEmpty(user_credentials)) {
			if ( user_credentials ) {
				headers1['Authorization'] = user_credentials;
				// headers1['Access-Key'] = 'AE698wLwHGPLvtuzF46V4P2h4yh3ru2MmkBKpsEA7bzQSHjQ3F';
			}
		}

		let requestedUrl = req.url;
		if ( requestedUrl.search('app.filevine.com') > -1 ) {
			let params = requestedUrl.split('?');
			let fv_key = params[1].split('=');
			headers1['filevine-api-key'] = fv_key[1];
			const httpRequest = new HttpRequest(<any>req.method,params[0]);
			req = Object.assign(req, httpRequest);
		}

		const authReq = req.clone({
			headers: new HttpHeaders(headers1)
		});

		return next.handle(authReq)
		.pipe(
			retry(0), 
			catchError(
				(error: HttpErrorResponse) => 
				{
					if (error.status == 401 || error.status == 403) {
						this.sharedService.routerNavigate('/login');
						this.sharedService.clearLocalStorage();
					}
					return throwError(error);
				}
				)
			);
	}
}